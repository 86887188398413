<template>
  <header class="w-100 absolute header-custom z-[1]">
    <Container is-large>
      <div class="py-3 md:px-8 flex align-center justify-between">
        <h1 class="flex-1">
          <NuxtLink to="/">
            <div class="header-log">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="208"
                height="20"
                viewBox="0 0 208 20"
                fill="none"
              >
                <g clip-path="url(#clip0_1452_1241)">
                  <path
                    d="M72.8297 11.4486C73.5075 11.1292 74.1023 10.7155 74.5979 10.2199C75.0978 9.72048 75.491 9.12349 75.7659 8.44679C76.0397 7.77227 76.178 6.99905 76.178 6.14776V6.09245C76.178 5.27911 76.0397 4.51999 75.7664 3.83625C75.4915 3.15087 75.094 2.54467 74.5844 2.03443C73.9445 1.39406 73.1263 0.888708 72.1525 0.531381C71.1889 0.178935 70.0768 0 68.8471 0H60.1953V20H63.1065V12.5151H68.057L73.6436 20H77.2358L71.2258 12.006C71.7908 11.871 72.3282 11.6844 72.8297 11.448V11.4486ZM72.0044 8.84153C71.5999 9.15277 71.1109 9.39623 70.5497 9.56486C69.9803 9.73729 69.3481 9.82459 68.6703 9.82459H63.1071V2.74583H68.698C70.1701 2.74583 71.3196 3.05815 72.114 3.67304C72.8904 4.27545 73.2678 5.09367 73.2678 6.17487V6.23018C73.2678 6.79246 73.1561 7.29619 72.9365 7.72726C72.7142 8.16213 72.4008 8.5368 72.0055 8.84099L72.0044 8.84153Z"
                    fill="white"
                  />
                  <path
                    d="M81.9776 11.276H92.4903V8.58548H81.9776V2.6904H93.7287V-0.00012207H79.0664V19.9999H93.8665V17.3094H81.9776V11.276Z"
                    fill="white"
                  />
                  <path
                    d="M110.266 14.7408L98.678 -0.00012207H96.0938V19.9999H98.9497V4.89943L110.841 19.9999H113.122V-0.00012207H110.266V14.7408Z"
                    fill="white"
                  />
                  <path
                    d="M131.632 2.85307C130.694 1.96165 129.56 1.25567 128.264 0.753572C126.97 0.25364 125.531 -0.00012207 123.99 -0.00012207H116.934V19.9999H123.99C125.533 19.9999 126.972 19.7418 128.266 19.2315C129.562 18.7202 130.694 18.0099 131.632 17.1196C132.572 16.2265 133.31 15.1567 133.823 13.941C134.335 12.727 134.595 11.4013 134.595 10.0001V9.94484C134.595 8.54427 134.335 7.22287 133.823 6.01642C133.31 4.81105 132.573 3.74666 131.632 2.85307ZM131.602 9.99961V10.0549C131.602 11.0656 131.421 12.0237 131.065 12.9021C130.709 13.7784 130.2 14.5467 129.551 15.186C128.903 15.8264 128.097 16.3361 127.158 16.701C126.215 17.0675 125.149 17.254 123.99 17.254H119.845V2.74517H123.99C125.147 2.74517 126.213 2.93549 127.155 3.31071C128.095 3.68539 128.901 4.20375 129.549 4.85226C130.199 5.50184 130.708 6.27506 131.064 7.15238C131.42 8.03133 131.602 8.98944 131.602 9.99961Z"
                    fill="white"
                  />
                  <path
                    d="M140.041 11.2761H150.554V8.58554H140.041V2.69046H151.792V-6.10352e-05H137.13V19.9999H151.93V17.3094H140.041V11.2761Z"
                    fill="white"
                  />
                  <path
                    d="M166.791 11.4486C167.469 11.1292 168.064 10.7155 168.559 10.2199C169.059 9.72048 169.452 9.12349 169.727 8.44679C170.001 7.77227 170.139 6.99905 170.139 6.14776V6.09245C170.139 5.27911 170.001 4.51999 169.728 3.83625C169.453 3.15087 169.056 2.54467 168.546 2.03443C167.906 1.39406 167.088 0.888708 166.114 0.531381C165.15 0.178935 164.038 0 162.809 0H154.156V20H157.067V12.5151H162.019L167.605 20H171.197L165.187 12.006C165.752 11.871 166.29 11.6844 166.791 11.448V11.4486ZM165.965 8.84153C165.562 9.15223 165.073 9.39569 164.511 9.56486C163.941 9.73729 163.31 9.82459 162.631 9.82459H157.068V2.74583H162.659C164.131 2.74583 165.281 3.05815 166.075 3.67304C166.851 4.27545 167.229 5.09421 167.229 6.17487V6.23018C167.229 6.79246 167.118 7.29619 166.897 7.72726C166.675 8.16267 166.361 8.53735 165.966 8.84099L165.965 8.84153Z"
                    fill="white"
                  />
                  <path
                    d="M176.342 11.2761H186.855V8.58554H176.342V2.69046H188.093V-6.10352e-05H173.431V19.9999H188.231V17.3094H176.342V11.2766V11.2761ZM187.493 18.0458V19.2631H174.168V0.736824H187.356V1.95412H175.605V9.32297H186.118V10.5403H175.605V18.0463H187.494L187.493 18.0458Z"
                    fill="white"
                  />
                  <path
                    d="M201.487 12.0046C202.052 11.8695 202.59 11.683 203.091 11.4466C203.769 11.1272 204.364 10.7135 204.86 10.2179C205.359 9.71853 205.753 9.12154 206.027 8.44484C206.301 7.77031 206.44 6.9971 206.44 6.1458V6.0905C206.44 5.27716 206.301 4.51804 206.028 3.83429C205.753 3.14892 205.356 2.54271 204.846 2.03248C204.206 1.39211 203.388 0.886755 202.414 0.529428C201.451 0.176981 200.338 -0.00195312 199.109 -0.00195312H190.457V19.998H193.368V12.5132H198.319L203.905 19.998H207.497L201.487 12.004V12.0046ZM198.688 11.7774H192.631V19.2622H191.194V0.736016H199.109C200.252 0.736016 201.279 0.900311 202.16 1.22294C203.033 1.54285 203.761 1.99073 204.324 2.55464C204.764 2.99439 205.106 3.51709 205.344 4.1092C205.582 4.70565 205.703 5.37259 205.703 6.09158V6.14689C205.703 6.90221 205.582 7.5827 205.345 8.16831C205.107 8.75282 204.768 9.2674 204.338 9.69792C203.903 10.1328 203.378 10.4977 202.777 10.7808C202.166 11.0687 201.495 11.2763 200.781 11.3973L200.185 11.4987L206.021 19.2628H204.274L198.688 11.7779L198.688 11.7774Z"
                    fill="white"
                  />
                  <path
                    d="M202.716 9.42438C203.199 9.05295 203.582 8.59477 203.854 8.06122C204.128 7.52442 204.266 6.90845 204.266 6.22904V6.17373C204.266 4.86751 203.782 3.82969 202.827 3.08901C201.9 2.37165 200.599 2.00781 198.96 2.00781H192.632V10.5598H198.932C199.682 10.5598 200.387 10.4617 201.025 10.2692C201.672 10.074 202.241 9.79038 202.716 9.42438ZM198.932 9.82345H193.369V2.7447H198.96C200.432 2.7447 201.582 3.05702 202.376 3.6719C203.153 4.27432 203.53 5.09254 203.53 6.17373V6.22904C203.53 6.79133 203.418 7.29506 203.199 7.72613C202.976 8.16099 202.663 8.53567 202.268 8.83986C201.863 9.15109 201.374 9.39455 200.813 9.56319C200.244 9.73561 199.611 9.82291 198.934 9.82291L198.932 9.82345Z"
                    fill="white"
                  />
                  <path
                    d="M0.00012207 19.7488H4.72779L7.45735 13.2188H2.73023L0.00012207 19.7488Z"
                    fill="white"
                  />
                  <path
                    d="M11.9626 0.199219H8.17188L11.9626 9.2674L16.3432 19.747H21.0709L12.8995 0.199219H11.9626Z"
                    fill="white"
                  />
                  <path
                    d="M18.826 0.199219L26.9974 19.747H31.7251L23.5537 0.199219H18.826Z"
                    fill="white"
                  />
                  <path
                    d="M43.2067 7.37937L41.284 11.9802L41.0541 12.5305L40.4007 14.0921L38.0371 19.747H42.7648L47.371 8.72735L50.9361 0.199219H46.2085L43.2067 7.37937Z"
                    fill="white"
                  />
                  <path
                    d="M34.2091 0.199219H29.4814L33.8344 10.9125H38.5621L34.2091 0.199219Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1452_1241">
                    <rect
                      width="208"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="header-log-small">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 408 253"
                fill="none"
                preserveAspectRatio="xMidYMid meet"
                style="width: clamp(40.315px, 100%, 80.63px); height: clamp(25px, 100%, 50px);"
              ><g clip-path="url(#clip0_1073_1860)"><path
                d="M34.9776 228.506C36.8488 227.642 38.5041 226.491 39.8716 225.123C41.239 223.756 42.3186 222.101 43.1102 220.229C43.9019 218.358 44.2618 216.199 44.2618 213.896V213.752C44.2618 211.521 43.9019 209.362 43.1102 207.491C42.3186 205.619 41.239 203.892 39.7996 202.525C38.0004 200.725 35.7693 199.358 33.0344 198.35C30.3715 197.343 27.2768 196.911 23.8942 196.911H0V252.328H8.06068V231.601H21.807L37.2806 252.328H47.2126L30.5874 230.161C32.1708 229.801 33.6102 229.298 35.0496 228.65L34.9776 228.506ZM32.6745 221.237C31.523 222.101 30.2276 222.748 28.6442 223.252C27.0609 223.756 25.3336 223.972 23.4623 223.972H8.06068V204.396H23.5343C27.6366 204.396 30.8033 205.259 32.9624 206.987C35.1215 208.642 36.1291 210.945 36.1291 213.896V214.04C36.1291 215.623 35.8412 216.991 35.1935 218.214C34.5458 219.438 33.6821 220.445 32.6026 221.309L32.6745 221.237Z"
                fill="black"
              /><path
                d="M60.3112 228.002H89.4592V220.589H60.3112V204.252H92.9137V196.767H52.2505V252.184H93.2736V244.699H60.3112V228.002Z"
                fill="black"
              /><path
                d="M138.687 237.574L106.588 196.767H99.4631V252.184H107.38V210.369L140.27 252.184H146.604V196.767H138.687V237.574Z"
                fill="black"
              /><path
                d="M197.847 204.684C195.256 202.237 192.089 200.294 188.49 198.854C184.892 197.487 180.933 196.767 176.615 196.767H157.039V252.184H176.615C180.862 252.184 184.892 251.465 188.49 250.025C192.089 248.586 195.184 246.643 197.847 244.196C200.437 241.749 202.525 238.726 203.892 235.415C205.331 232.033 206.051 228.362 206.051 224.476V224.332C206.051 220.445 205.331 216.775 203.892 213.464C202.453 210.154 200.437 207.203 197.847 204.684ZM197.775 224.476V224.62C197.775 227.426 197.271 230.089 196.263 232.536C195.256 234.983 193.888 237.07 192.089 238.87C190.29 240.669 188.059 242.036 185.468 243.044C182.877 244.052 179.926 244.555 176.687 244.555H165.172V204.396H176.687C179.926 204.396 182.877 204.9 185.468 205.979C188.059 206.987 190.29 208.426 192.089 210.226C193.888 212.025 195.328 214.184 196.263 216.631C197.271 219.078 197.775 221.741 197.775 224.548V224.476Z"
                fill="black"
              /><path
                d="M221.165 228.002H250.313V220.589H221.165V204.252H253.696V196.767H213.104V252.184H254.127V244.699H221.165V228.002Z"
                fill="black"
              /><path
                d="M295.294 228.506C297.166 227.642 298.821 226.491 300.188 225.123C301.556 223.756 302.635 222.101 303.427 220.229C304.219 218.358 304.579 216.199 304.579 213.896V213.752C304.579 211.521 304.219 209.362 303.427 207.491C302.635 205.619 301.556 203.892 300.116 202.525C298.317 200.725 296.086 199.358 293.351 198.35C290.688 197.343 287.594 196.911 284.211 196.911H260.245V252.328H268.306V231.601H282.052L297.526 252.328H307.457L290.832 230.161C292.416 229.801 293.855 229.298 295.294 228.65V228.506ZM292.991 221.237C291.84 222.101 290.544 222.748 288.961 223.252C287.378 223.756 285.65 223.972 283.779 223.972H268.378V204.396H283.851C287.953 204.396 291.12 205.259 293.279 206.987C295.438 208.642 296.446 210.945 296.446 213.896V214.04C296.446 215.623 296.158 216.991 295.51 218.214C294.863 219.438 293.999 220.445 292.919 221.309L292.991 221.237Z"
                fill="black"
              /><path
                d="M321.708 228.002H350.856V220.517H321.708V204.18H354.238V196.695H313.647V252.112H354.67V244.627H321.708V227.93V228.002ZM352.583 246.786V250.169H315.662V198.854H352.223V202.237H319.692V222.676H348.84V226.059H319.692V246.858H352.655L352.583 246.786Z"
                fill="black"
              /><path
                d="M391.375 230.017C392.958 229.657 394.398 229.154 395.837 228.506C397.708 227.642 399.363 226.491 400.731 225.123C402.098 223.756 403.178 222.101 403.97 220.229C404.761 218.358 405.121 216.199 405.121 213.896V213.752C405.121 211.521 404.761 209.362 403.97 207.491C403.178 205.619 402.098 203.892 400.659 202.525C398.86 200.725 396.629 199.358 393.894 198.35C391.231 197.343 388.136 196.911 384.753 196.911H360.787V252.328H368.848V231.601H382.594L398.068 252.328H408L391.375 230.161V230.017ZM383.602 229.37H366.833V250.097H362.874V198.782H384.825C387.992 198.782 390.871 199.214 393.246 200.15C395.693 201.013 397.708 202.309 399.22 203.82C400.443 205.044 401.379 206.483 402.026 208.138C402.674 209.794 403.034 211.665 403.034 213.608V213.752C403.034 215.839 402.674 217.71 402.026 219.366C401.379 221.021 400.443 222.388 399.22 223.612C397.996 224.835 396.557 225.843 394.901 226.635C393.246 227.426 391.375 228.002 389.36 228.362L387.704 228.65L403.898 250.169H399.076L383.602 229.441V229.37Z"
                fill="black"
              /><path
                d="M394.757 222.892C396.125 221.885 397.133 220.589 397.924 219.15C398.716 217.639 399.076 215.983 399.076 214.04V213.896C399.076 210.298 397.708 207.419 395.117 205.332C392.526 203.316 388.928 202.309 384.394 202.309H366.833V225.987H384.25C386.337 225.987 388.28 225.699 390.079 225.195C391.879 224.62 393.462 223.9 394.757 222.82V222.892ZM384.322 223.972H368.92V204.396H384.394C388.496 204.396 391.663 205.26 393.822 206.987C395.981 208.642 396.989 210.945 396.989 213.896V214.04C396.989 215.623 396.701 216.991 396.053 218.214C395.405 219.438 394.542 220.445 393.462 221.309C392.31 222.173 391.015 222.82 389.432 223.324C387.848 223.828 386.121 224.044 384.25 224.044L384.322 223.972Z"
                fill="black"
              /><path
                d="M0 156.608H37.8564L59.7354 104.285H21.879L0 156.608Z"
                fill="black"
              /><path
                d="M95.7926 0H65.4211L95.7926 72.6181L130.914 156.608H168.771L103.35 0H95.7926Z"
                fill="black"
              /><path
                d="M150.778 0L216.271 156.608H254.127L188.706 0H150.778Z"
                fill="black"
              /><path
                d="M346.106 57.5043L330.704 94.3532L328.833 98.7434L323.651 111.266L304.723 156.608H342.579L379.5 68.2999L408 0H370.144L346.106 57.5043Z"
                fill="black"
              /><path
                d="M274.063 0H236.135L271.04 85.7887H308.897L274.063 0Z"
                fill="black"
              /></g><defs><clipPath id="clip0_1073_1860"><rect
                width="408"
                height="252.184"
                fill="white"
              /></clipPath></defs></svg>
            </div>
          </NuxtLink>
        </h1>

        <div class="hidden lg:flex gap-[0.25rem] align-center flex-1 justify-end max-w-[400px]">
          <NuxtLink
            data-evt="btn_Login"
            to="/generate"
            target="_blank"
            class="w-full text-center custom-login-button"
          >
            ログイン
          </NuxtLink>

          <div
            class="w-full custom-background"
          >
            <NuxtLink
              to="/generate"
              target="_blank"
              data-evt="btn_new account"
            >
              新規アカウント作成(無料)
            </NuxtLink>
          </div>
        </div>
        <v-icon
          size="32"
          class="lg:hidden"
          @click="toggleHamburgerMenu"
        >
          mdi-menu
        </v-icon>
      </div>
    </Container>
  </header>

  <HamburgerMenu
    v-if="isMenuOpen"
    @close="toggleHamburgerMenu"
  />
</template>

<script setup lang="ts">
const isMenuOpen = ref(false)
const config = useRuntimeConfig()
const toggleHamburgerMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}
</script>

<style scoped>
.header-log-small {
  display: none;
}
.custom-background {
  width: 450px;
  font-size: 14px;
  border-radius: 4px;
  background: #09334e;
  color: white;
  display: flex;
  padding: 15px 16px;
  align-items: center;
  justify-content: center;
}

.custom-login-button {
  padding: 10px 10px;
  border: 1px white solid;
  border-radius: 5px;
  color: white;
}

@media (max-width: 1090px) {
  .header-log {
    display: none;
  }

  .header-log-small {
    display: block;
  }
  .header-custom {
    position: static !important;
  }

  .custom-login-button {
    padding: 10px 10px;
    border: 1px rgb(15, 12, 12) solid;
    color: black;
    border-radius: 5px;
  }

  .login-text {
    color: black;
  }
}
</style>
